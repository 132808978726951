import React from 'react';

const Impressum = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video3.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>印刷</span></h1>
    </div>

    <div className='imp-sec3-bg'>
    <div className='imp-sec3'>
      <h3>印刷</h3>
      <p>本网站用于展示及提供有关 Shen KG 产品的信息。它为所有感兴趣的人提供了自由和直接的访问。所有权利归属于位于奥地利萨尔茨堡的 Shen KG。根据出版法，Shen KG 也对此负有责任。
</p>
<h3>使用条款</h3>
<p>此网站及其构成部分受版权保护。

最终用户可以在线在其计算机上查看此网站的内容，并根据奥地利版权法在其他情况下将内容用于自己的私人目的。所有进一步的使用（例如，为了发布而制作类似的复制品，以及为了数字传输而制作数字复制品，例如在互联网上进行数字传输，翻译或其他处理等）仅在出版商的书面同意下才允许。出版商保留根据情况不授予同意的权利。对于超出查看和下载网站之外的用途，尤其是建立所谓的深层链接以及将网站嵌入到第三方网站的框架中，同样适用。建立简单的HTML链接是允许的，但出版商保留随时要求建立者在合理期限内删除链接的权利，无需提供理由。

在获取和发布信息方面，出版商在实现最新性，准确性和数据完整性方面进行了最大程度的谨慎。然而，对信息的准确性和完整性不作任何保证，并且在因错误信息引起的损害以及因信息传输过程造成的损害方面，除非出版商可以证明其有重大疏忽或故意，否则将不承担任何责任。

访问承载此网站的服务器的所有访问都将在日期，计算机地址和读取的文档方面进行注册和存储。这些数据可能会用于统计目的，并可能以匿名方式进行公开。在任何情况下都不会将数据传递给第三方进行商业用途。

Shen KG的互联网提供内容中包含了指向其他供应商网站的链接，这些链接是为了您的利益而提供的。如果您遵循这些链接，您将离开Shen KG的网站。Shen KG对外部网站的设计和内容没有影响，因此即使Shen KG在其网站上设置了指向这些外部网页的链接，它仍然与所有外部内容保持距离。此声明适用于我们主页上显示的所有链接以及链接和/或任何横幅导向的所有页面的所有内容。

通过访问此网站，最终用户认可上述使用条款为具有约束力。
</p>
<h3>拥有媒体所有权者</h3>
<p>Shen KG<br></br>
Grießgasse 17 . 5020 Salzburg . Austria<br></br> 
T +43 660 3285 895  . salzburgsiona@gmail.com<br></br>
www.siona.at
</p>
<h3>网站与设计</h3>
<div className='link-imp'><a href='https://farbzirkel.com'>www.farbzirkel.com</a></div>
    </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- 优雅的完美</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>地址</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>联系方式</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>周一至周五：上午9点至下午6点。<br></br>周六：上午9点至下午5点。</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>周一至周五：上午9点至晚上7点。<br></br>周六：上午9点至下午6点。</p>
        </div>

        <div className='footer-sec5'>
          <h4>法律链接</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Impressum;
