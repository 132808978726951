import React from 'react';

const About = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video1.mp4" type="video/mp4" />
      </video>
      <h1>ÜBER <span className='sec-bold-sec'>SIONA</span></h1>
    </div>


<div className='about-sec2'>
      <div className='img-sec2'>
        <img src="../assets/5.jpg" alt="A handbag from Siona"></img>
      </div>

      <div className='text-sec2'>
        <div className='sec2-text-button'>
        <h2>Die <span id="bold-h">Geschichte.</span></h2>
        <p>Die Marke SIONA, mit ihrem Hauptsitz in der malerischen Stadt Salzburg, Österreich, hat sich einen bemerkenswerten Ruf für ihre handgefertigten Taschen erworben. Jedes dieser Meisterwerke erzählt eine Geschichte von Präzision, Eleganz und künstlerischem Ausdruck. Der Ursprung dieser außergewöhnlichen Marke liegt jedoch nicht nur in den Alpen, sondern auch in den traditionellen Lederwerkstätten Italiens.</p>
        </div>
      </div>
    </div>





    <div className='about-sec3-bg'>
    <div className='about-sec3'>
      <p>Die SIONA Taschen sind das Ergebnis einer tiefen Zusammenarbeit mit einem erfahrenen italienischen Lederhandwerker. Er fertigt jedes Stück aus feinstem Leder mit meisterhafter Handarbeit an, wodurch eine einzigartige Verbindung zwischen Material und Künstler entsteht. Jede Tasche wird sorgfältig gestaltet, genäht und veredelt, bis sie den hohen Ansprüchen von SIONA gerecht wird.</p>
    </div>
    </div>


    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- ELEGANZ IN PERFEKTION.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESSE</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>KONTAKT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9 - 18 UHR<br></br>SA: 9 - 17 UHR</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9 - 19 UHR<br></br>SA: 9 - 18 UHR</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRESSUM</a><br></br><a href='/privacy'>DATENSCHUTZ</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default About;
