import React from 'react';

const About = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video1.mp4" type="video/mp4" />
      </video>
      <h1>ABOUT <span className='sec-bold-sec'>SIONA</span></h1>
    </div>


<div className='about-sec2'>
      <div className='img-sec2'>
        <img src="../assets/5.jpg" alt="A handbag from Siona"></img>
      </div>

      <div className='text-sec2'>
        <div className='sec2-text-button'>
        <h2>The <span id="bold-h">Story.</span></h2>
        <p>The brand SIONA, headquartered in the picturesque city of Salzburg, Austria, has earned a remarkable reputation for its handcrafted bags. Each of these masterpieces tells a story of precision, elegance, and artistic expression. However, the origin of this extraordinary brand is not only in the Alps but also in the traditional leather workshops of Italy.</p>
        </div>
      </div>
    </div>





    <div className='about-sec3-bg'>
    <div className='about-sec3'>
      <p>The SIONA bags are the result of a close collaboration with an experienced Italian leather craftsman. He meticulously crafts each piece from the finest leather, creating a unique connection between the material and the artist. Every bag is carefully designed, stitched, and refined until it meets the high standards set by SIONA.</p>
    </div>
    </div>



    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- Elegance in perfection.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESS</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>CONTACT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9am - 6pm<br></br>SA: 9am - 5pm</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9am - 7pm<br></br>SA: 9am - 6pm</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default About;
