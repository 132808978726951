import React from 'react';

const Impressum = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video3.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>Imprint</span></h1>
    </div>

    <div className='imp-sec3-bg'>
    <div className='imp-sec3'>
      <h3>Imprint</h3>
      <p>The website serves for self-presentation as well as for information about the products of Shen KG. It grants free and direct access to anyone interested. All rights are owned by Shen KG based in Salzburg. Shen KG is also responsible according to the Press Law.
</p>
<h3>Terms of Use:</h3>
<p>This website and its constituent parts are protected by copyright.

The end user may view the content of this website online on their computer and may use the content for their own private purposes in accordance with Austrian copyright law. All further uses (e.g. creating analog reproductions for publication, creating digital reproductions for digital transmission on the internet, translations, or other modifications, etc.) are only permitted with the written consent of the publisher. The publisher reserves the right to refrain from granting consent if deemed necessary. The same applies to uses that go beyond viewing and downloading the website, particularly setting up so-called deep links and embedding the website into frames of third-party websites. Setting up simple HTML links is allowed, though the publisher reserves the right to request the deletion of the link from the creator at any time and without specifying a reason within a reasonable period.

The publisher exercises the utmost care in procuring and publishing information with regard to the timeliness, accuracy, and completeness of the data. However, no guarantee is provided for the correctness and completeness of the information, and any liability for damages resulting from incorrect information or damages arising from the transmission of information is excluded, unless the publisher can be proven to have acted with gross negligence or intent.

All access to the server hosting this website is registered and stored with regard to date, computer address, and read document. The data may be evaluated for statistical purposes and possibly published in an anonymized form. Under no circumstances will the data be passed on to third parties for commercial purposes.

The internet offering of Shen KG contains links that refer to websites of other providers in your interest. If you follow these links, you leave the websites of Shen KG. It has no influence on the design and content of foreign websites and therefore distances itself from all foreign content, even if a link to these external sites has been provided by Shen KG. This declaration applies to all links displayed on our homepage and to all content of the pages to which the links and/or any banners registered with us lead.

By visiting this website, the end user acknowledges the above terms of use as binding.
</p>
<h3>Media Owner:</h3>
<p>Shen KG<br></br>
Grießgasse 17 . 5020 Salzburg . Austria<br></br> 
T +43 660 3285 895  . salzburgsiona@gmail.com<br></br>
www.siona.at
</p>
<h3>Website & Design:</h3>
<div className='link-imp'><a href='https://farbzirkel.com'>www.farbzirkel.com</a></div>
    </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- Elegance in perfection.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESS</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>CONTACT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9am - 6pm<br></br>SA: 9am - 5pm</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9am - 7pm<br></br>SA: 9am - 6pm</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Impressum;
