import React, { useState } from 'react';

const Home = () => {

  const images = ["../assets/slider/siona-weekender-bag.jpg", "../assets/slider/siona-handbag-1.jpg", "../assets/slider/siona-small-bag-1.jpg", "../assets/slider/siona-aktentasche.jpg", "../assets/slider/siona-backpack-men1.jpg", "../assets/slider/siona-handbag-2.jpg", "../assets/slider/siona-handbag-3.jpg", "../assets/slider/siona-handbag-4.jpg", "../assets/slider/siona-handbag-5.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  // const showImage = (index) => {
  //   setCurrentIndex(index);
  // };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };


  return (
    <div>
      <div className='home-hero-text'>
        <div>
        <h1>优雅的完美</h1>
        <p>来自萨尔茨堡的纯粹精致</p>
        
        <div className='button-hero'>
          <div className='button-primary'>
            <a href='/bags'>我们的款式/模型/型号/样式</a>
          </div>
        </div>
        </div>
      </div>
    <div className='hero'>
    </div>

    <div className='sec0'>
      <div className='sec1'>
        <div className='text-home'>
          <p>沉浸在我们手工制作的无比优雅的包袋中，它们直接源自意大利的深处，流入您的生活。每个SIONA包袋都是由一位高超的意大利皮革艺术师用最大的投入和完美制作而成。只有最优质的材料才能进入我们独特的设计中。每个结构，每道缝线和每个提手都是以无与伦比的精准和关注细节的方式制作的。在萨尔茨堡如画的背景中，您将发现SIONA包袋的唯一和独家来源。</p>
        </div>

        <div className='gallerie-home'>
        <div className="gallery">
        <div>
        <img src={images[currentIndex]} alt={`Siona Bag ${currentIndex + 1}`}></img>
            <div className="nav-buttons">
              <img id="prev" src="../assets/icons/arrow-prev.svg" alt="Previous Icon" onClick={handlePrevClick}></img>
              <img id="next" src="../assets/icons/arrow-next.svg" alt="Next Icon" onClick={handleNextClick}></img>
            </div>
            </div>
        </div>
        </div>
      </div>

      <div className='sec1-mobile'>
        <div>
        <div className='text-home'>
          <p>沉浸在我们手工制作的无比优雅的包袋中，它们直接源自意大利的深处，流入您的生活。每个SIONA包袋都是由一位高超的意大利皮革艺术师用最大的投入和完美制作而成。只有最优质的材料才能进入我们独特的设计中。每个结构，每道缝线和每个提手都是以无与伦比的精准和关注细节的方式制作的。在萨尔茨堡如画的背景中，您将发现SIONA包袋的唯一和独家来源。</p>
        </div>

        <div className='gallerie-home'>
        <div className="gallery">
        <div>
        <img src={images[currentIndex]} alt={`Siona Bag ${currentIndex + 1}`}></img>
            <div className="nav-buttons">
              <img id="prev" src="../assets/icons/arrow-prev.svg" alt="Previous Icon" onClick={handlePrevClick}></img>
              <img id="next" src="../assets/icons/arrow-next.svg" alt="Next Icon" onClick={handleNextClick}></img>
            </div>
            </div>
        </div>
        </div>
        </div>
      </div>
    </div>

    <div className='sec2'>
      <div className='img-sec2'>
        <img src="../assets/siona-handbag-home2.jpg" alt="Brown Handbag"></img>
      </div>

      <div className='text-sec2'>
        <div className='sec2-text-button'>
        <h2>探索 <span id="bold-h">SIONA</span> 的世界。 </h2>
        <div className='button-sec2'>
          <div className='button-primary'>
            <a href='/contact'>我们的门店</a>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className='sec3'>
      <div className='img-sec3'>
        <img src="../assets/siona-diashow.jpg" alt="Wallet on a stone"></img>
      </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- 优雅的完美</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>地址</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>联系方式</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>周一至周五：上午9点至下午6点。<br></br>周六：上午9点至下午5点。</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>周一至周五：上午9点至晚上7点。<br></br>周六：上午9点至下午6点。</p>
        </div>

        <div className='footer-sec5'>
          <h4>法律链接</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>

    </div>



  );
};

export default Home;
