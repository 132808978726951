import React from 'react';

const Privacy = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video3.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>Privacy Policy</span></h1>
    </div>

    <div className='imp-sec3-bg'>
    <div className='imp-sec3'>
    <h3>Privacy Policy</h3>
<p>Thank you for visiting our website. Your privacy is important to us, and we want to transparently explain how we handle your personal data. Please read the following privacy policy carefully to understand how we collect, use, protect, and disclose information.</p>
<h3>1. Collection of Information:</h3>
<p>Our website does not collect personal data or personal information from visitors. We do not gather any information that could identify individual persons.</p>
<h3>2. Use of Cookies:</h3>
<p>Our website may use cookies to provide certain functions. These cookies do not store personal data and are used solely to enhance your browsing experience. You can control the use of cookies in your browser settings.</p>
<h3>3. Use of Google Fonts:</h3>
<p>We use Google Fonts to enhance the design of our website. Please note that when loading Google Fonts, information about your usage may be transmitted to Google. Please consult Google's privacy policies to learn more about Google Fonts' data processing. The Google Fonts have been locally installed on our server and are not loaded externally.</p>
<h3>4. External Links:</h3>
<p>Our website may contain links to external websites whose privacy practices we are not responsible for. Please review the privacy policies of these websites before providing personal information on them.</p>
<h3>5. Data Security:</h3>
<p>While we take all reasonable measures to protect your data, we cannot guarantee absolute security. Please note that the internet is not a completely secure environment.</p>
<h3>6. Changes to the Privacy Policy:</h3>
<p>We reserve the right to change or update this privacy policy at any time. Please check this page regularly to stay informed.</p>
<h3>7. Contact:</h3>
<p>If you have questions or concerns about the privacy policy or the use of your data, please feel free to contact us. This privacy policy was last updated on August 20, 2023. Please note that this privacy policy is general in nature and may require tailored adjustments to fit your specific situation. If you need legal advice or a more comprehensive privacy policy, we recommend consulting a lawyer or privacy expert.</p>


    </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- Elegance in perfection.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESS</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>CONTACT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9am - 6pm<br></br>SA: 9am - 5pm</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9am - 7pm<br></br>SA: 9am - 6pm</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Privacy;
