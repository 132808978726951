import React, { useState } from 'react';

const PhotoGallery = () => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  // Use require.context to dynamically require all .jpg images in the /assets/gallery folder
  const importAll = (context) => context.keys().map(context);
  const imageContext = require.context('../../public/assets/gallery', false, /\.jpg$/);
  const images = importAll(imageContext);

  const openPhoto = (photo) => {
    setSelectedPhoto(photo);
  };

  const closePhoto = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="photo-gallery">
      {images.map((image, index) => (
        <div
          key={index}
          className="photo-container"
          onClick={() => openPhoto(image)}
        >
          <img src={image} alt={`Siona ${index + 1}`} />
        </div>
      ))}

      {selectedPhoto && (
        <div className="overlay" onClick={closePhoto}>
          <div className="modal">
            <img src={selectedPhoto} alt="Siona Handbag" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
