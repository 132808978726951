import React from 'react';

const Privacy = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video3.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>Datenschutz</span></h1>
    </div>

    <div className='imp-sec3-bg'>
    <div className='imp-sec3'>
    <h3>Datenschutzerklärung</h3>
<p>Vielen Dank, dass Sie unsere Webseite besuchen. Ihre Privatsphäre ist uns wichtig, und wir möchten Ihnen transparent darlegen, wie wir mit Ihren persönlichen Daten umgehen. Bitte lesen Sie die folgende Datenschutzerklärung sorgfältig durch, um zu verstehen, wie wir Informationen sammeln, verwenden, schützen und weitergeben.</p>
<h3>1. Erfassung von Informationen: </h3>
 <p>Unsere Webseite erfasst keine personenbezogenen Daten oder persönlichen Informationen von Besuchern. Wir sammeln keinerlei Informationen, die eine Identifizierung einzelner Personen ermöglichen.</p>
<h3>2. Verwendung von Cookies: </h3>
<p>Unsere Webseite verwendet möglicherweise Cookies, um bestimmte Funktionen bereitzustellen. Diese Cookies speichern keine personenbezogenen Daten und dienen lediglich dazu, Ihre Browsing-Erfahrung zu verbessern. Sie können die Verwendung von Cookies in Ihren Browsereinstellungen kontrollieren.</p>
<h3>3. Verwendung von Google Fonts: </h3>
<p>Wir verwenden Google Fonts, um unsere Webseite ansprechend zu gestalten. Beachten Sie bitte, dass beim Laden von Google Fonts Informationen über Ihre Nutzung an Google übertragen werden können. Bitte konsultieren Sie die Datenschutzrichtlinien von Google, um mehr über die Datenverarbeitung durch Google Fonts zu erfahren. Die Google Fonts wurden lokal auf unserem Server installiert und werden nicht extern geladen.</p>
<h3>4. Externe Links: </h3>
<p>Unsere Webseite kann Links zu externen Websites enthalten, für deren Datenschutzpraktiken wir nicht verantwortlich sind. Bitte überprüfen Sie die Datenschutzerklärungen dieser Websites, bevor Sie persönliche Informationen auf ihnen bereitstellen.</p>
<h3>5. Datensicherheit: </h3>
<p>Obwohl wir alle angemessenen Maßnahmen ergreifen, um Ihre Daten zu schützen, können wir keine absolute Sicherheit garantieren. Bitte bedenken Sie, dass das Internet keine hundertprozentig sichere Umgebung ist. </p>
<h3>6. Änderungen an der Datenschutzerklärung: </h3>
 <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern oder zu aktualisieren. Bitte überprüfen Sie diese Seite regelmäßig, um auf dem Laufenden zu bleiben. </p>
<h3>7. Kontakt: </h3>
<p>Wenn Sie Fragen oder Bedenken zur Datenschutzerklärung oder zur Verwendung Ihrer Daten haben, können Sie uns gerne kontaktieren.
Diese Datenschutzerklärung wurde zuletzt aktualisiert am 20.08.2023.
Bitte beachten Sie, dass diese Datenschutzerklärung allgemein gehalten ist und auf Ihre spezifische Situation zugeschnittene Anpassungen erfordern kann. Wenn Sie rechtlichen Rat oder eine umfassendere Datenschutzerklärung benötigen, empfehlen wir, sich an einen Rechtsanwalt oder Datenschutzexperten zu wenden. </p>


    </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- ELEGANZ IN PERFEKTION.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESSE</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>KONTAKT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9 - 18 UHR<br></br>SA: 9 - 17 UHR</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9 - 19 UHR<br></br>SA: 9 - 18 UHR</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRESSUM</a><br></br><a href='/privacy'>DATENSCHUTZ</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Privacy;
