import React from 'react';

const Privacy = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video3.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>隐私政策</span></h1>
    </div>

    <div className='imp-sec3-bg'>
    <div className='imp-sec3'>
    <h3>隐私政策</h3>
<p>感谢您访问我们的网站。您的隐私对我们非常重要，我们希望透明地解释我们如何处理您的个人数据。请仔细阅读以下隐私政策，以了解我们如何收集、使用、保护和披露信息。</p>
<h3>1. 信息收集：</h3>
<p>我们的网站不收集访客的个人数据或个人信息。我们不收集任何可以识别个别人员的信息。</p>
<h3>2. 使用 Cookie：</h3>
<p>我们的网站可能使用 Cookie 提供某些功能。这些 Cookie 不存储个人数据，仅用于增强您的浏览体验。您可以在浏览器设置中控制 Cookie 的使用。</p>
<h3>3. 使用 Google Fonts：</h3>
<p>我们使用 Google Fonts 来增强我们的网站设计。请注意，加载 Google Fonts 时，关于您的使用情况的信息可能会传输给 Google。请查阅 Google 的隐私政策，了解更多关于 Google Fonts 数据处理的信息。Google Fonts 已在我们的服务器上进行了本地安装，不会从外部加载。</p>
<h3>4. 外部链接：</h3>
<p>我们的网站可能包含指向外部网站的链接，其隐私做法不由我们负责。在向这些网站提供个人信息之前，请查阅这些网站的隐私政策。</p>
<h3>5. 数据安全：</h3>
<p>虽然我们采取一切合理的措施来保护您的数据，但我们不能保证绝对的安全性。请注意，互联网并不是完全安全的环境。</p>
<h3>6. 隐私政策的变更：</h3>
<p>我们保留随时更改或更新本隐私政策的权利。请定期查看此页面以保持了解。</p>
<h3>7. 联系方式：</h3>
<p>如果您对隐私政策或数据使用有任何问题或疑虑，请随时与我们联系。此隐私政策最后更新于2023年8月20日。请注意，此隐私政策具有一般性质，可能需要根据您的特定情况进行调整。如果您需要法律咨询或更全面的隐私政策，我们建议咨询律师或隐私专家。</p>

    </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- 优雅的完美</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>地址</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>联系方式</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>周一至周五：上午9点至下午6点。<br></br>周六：上午9点至下午5点。</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>周一至周五：上午9点至晚上7点。<br></br>周六：上午9点至下午6点。</p>
        </div>

        <div className='footer-sec5'>
          <h4>法律链接</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Privacy;
