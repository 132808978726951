import React from 'react';
import PhotoGallery from '../../components/PhotoGallery';

const Bags = () => {

  return (
    <div className='bags'>
         <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video2.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>我们的包包</span></h1>
    </div>
    <div className='gallery-bags'>
    <PhotoGallery />
    </div>

    

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- 优雅的完美</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>地址</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>联系方式</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>周一至周五：上午9点至下午6点。<br></br>周六：上午9点至下午5点。</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>周一至周五：上午9点至晚上7点。<br></br>周六：上午9点至下午6点。</p>
        </div>

        <div className='footer-sec5'>
          <h4>法律链接</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    
    </div>
  );
};

export default Bags;
