import React from 'react';

const About = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video1.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>关于我们</span></h1>
    </div>


<div className='about-sec2'>
      <div className='img-sec2'>
        <img src="../assets/5.jpg" alt="A handbag from Siona"></img>
      </div>

      <div className='text-sec2'>
        <div className='sec2-text-button'>
        <h2><span id="bold-h">故事</span></h2>
        <p>SIONA品牌总部位于奥地利风景如画的城市萨尔茨堡，以其手工制作的手袋赢得了卓越的声誉。这些杰作中的每一个都讲述了精准、优雅和艺术表达的故事。然而，这个非凡品牌的起源不仅在阿尔卑斯山，还在意大利传统的皮革工坊中。</p>
        </div>
      </div>
    </div>





    <div className='about-sec3-bg'>
    <div className='about-sec3'>
      <p>SIONA手袋是与经验丰富的意大利皮革工匠密切合作的成果。他精心制作每一件作品，选用最优质的皮革，创造了材料与艺术家之间独特的联系。每个手袋都经过精心设计、缝制和精炼，直到符合SIONA设定的高标准。</p>
    </div>
    </div>



    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- 优雅的完美</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>地址</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>联系方式</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>周一至周五：上午9点至下午6点。<br></br>周六：上午9点至下午5点。</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>周一至周五：上午9点至晚上7点。<br></br>周六：上午9点至下午6点。</p>
        </div>

        <div className='footer-sec5'>
          <h4>法律链接</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default About;
