import React, { useState } from 'react';

const Home = () => {

  const images = ["../assets/slider/siona-weekender-bag.jpg", "../assets/slider/siona-handbag-1.jpg", "../assets/slider/siona-small-bag-1.jpg", "../assets/slider/siona-aktentasche.jpg", "../assets/slider/siona-backpack-men1.jpg", "../assets/slider/siona-handbag-2.jpg", "../assets/slider/siona-handbag-3.jpg", "../assets/slider/siona-handbag-4.jpg", "../assets/slider/siona-handbag-5.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  // const showImage = (index) => {
  //   setCurrentIndex(index);
  // };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };


  return (
    <div>
      <div className='home-hero-text'>
        <div>
        <h1>Eleganz in Perfektion</h1>
        <p>Pure Rafinesse aus Salzburg</p>
        
        <div className='button-hero'>
          <div className='button-primary'>
            <a href='/bags'>Unsere Modelle</a>
          </div>
        </div>
        </div>
      </div>
    <div className='hero'>
    </div>

    <div className='sec0'>
      <div className='sec1'>
        <div className='text-home'>
          <p>Tauchen Sie ein in die unvergleichliche Eleganz unserer handgefertigten Taschen, die aus den Tiefen Italiens direkt in Ihr Leben strömen. Jede SIONA Tasche wird mit größter Hingabe und Perfektion von den geschickten Händen eines meisterhaften italienischen Lederkünstlers gefertigt. Nur die erlesensten Materialien finden den Weg in unsere exklusiven Designs. Jede Struktur, jede Naht und jeder Griff wird mit unübertroffener Präzision und Sorgfalt hergestellt. In der malerischen Kulisse von Salzburg finden Sie die einzige und exklusive Quelle von SIONA Taschen.</p>
        </div>

        <div className='gallerie-home'>
        <div className="gallery">
        <div>
        <img src={images[currentIndex]} alt={`Siona Tasche ${currentIndex + 1}`}></img>
            <div className="nav-buttons">
              <img id="prev" src="../assets/icons/arrow-prev.svg" alt="Previous Icon" onClick={handlePrevClick}></img>
              <img id="next" src="../assets/icons/arrow-next.svg" alt="Next Icon" onClick={handleNextClick}></img>
            </div>
            </div>
        </div>
        </div>
      </div>

      <div className='sec1-mobile'>
        <div>
        <div className='text-home'>
          <p>Tauchen Sie ein in die unvergleichliche Eleganz unserer handgefertigten Taschen, die aus den Tiefen Italiens direkt in Ihr Leben strömen. Jede SIONA Tasche wird mit größter Hingabe und Perfektion von den geschickten Händen eines meisterhaften italienischen Lederkünstlers gefertigt. Nur die erlesensten Materialien finden den Weg in unsere exklusiven Designs. Jede Struktur, jede Naht und jeder Griff wird mit unübertroffener Präzision und Sorgfalt hergestellt. In der malerischen Kulisse von Salzburg finden Sie die einzige und exklusive Quelle von SIONA Taschen.</p>
        </div>

        <div className='gallerie-home'>
        <div className="gallery">
        <div>
        <img src={images[currentIndex]} alt={`Siona Tasche ${currentIndex + 1}`}></img>
            <div className="nav-buttons">
              <img id="prev" src="../assets/icons/arrow-prev.svg" alt="Previous Icon" onClick={handlePrevClick}></img>
              <img id="next" src="../assets/icons/arrow-next.svg" alt="Next Icon" onClick={handleNextClick}></img>
            </div>
            </div>
        </div>
        </div>
        </div>
      </div>
    </div>

    <div className='sec2'>
      <div className='img-sec2'>
        <img src="../assets/siona-handbag-home2.jpg" alt="Braune Handtasche"></img>
      </div>

      <div className='text-sec2'>
        <div className='sec2-text-button'>
        <h2>Entdecke die Welt von <span id="bold-h">Siona.</span></h2>
        <div className='button-sec2'>
          <div className='button-primary'>
            <a href='/contact'>Unsere Stores</a>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className='sec3'>
      <div className='img-sec3'>
        <img src="../assets/siona-diashow.jpg" alt="Geldbörse auf Stein"></img>
      </div>
    </div>


    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- ELEGANZ IN PERFEKTION.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESSE</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>KONTAKT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9 - 18 UHR<br></br>SA: 9 - 17 UHR</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9 - 19 UHR<br></br>SA: 9 - 18 UHR</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRESSUM</a><br></br><a href='/privacy'>DATENSCHUTZ</a></p>
        </div>


      </div>
    </footer>

    </div>



  );
};

export default Home;
