import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//ENGLISH SITES
import HomeEn from './pages/en/Home';
import BagsEn from './pages/en/Bags';
import AboutEn from './pages/en/About';
import ContactEn from './pages/en/Contact';
import ImpressumEn from './pages/en/Impressum';
import PrivacyEn from './pages/en/Privacy';
//GERMAN SITES
import HomeDe from './pages/de/Home';
import BagsDe from './pages/de/Bags';
import AboutDe from './pages/de/About';
import ContactDe from './pages/de/Contact';
import ImpressumDe from './pages/de/Impressum';
import PrivacyDe from './pages/de/Privacy';

//CHINESE SITES
import HomeChn from './pages/chn/Home';
import BagsChn from './pages/chn/Bags';
import AboutChn from './pages/chn/About';
import ContactChn from './pages/chn/Contact';
import ImpressumChn from './pages/chn/Impressum';
import PrivacyChn from './pages/chn/Privacy';

const AppRouter = () => {
  const [language, setLanguage] = useState(() => {
    const storedLanguage = JSON.parse(window.localStorage.getItem('language'));
    return storedLanguage !== null ? storedLanguage : 'en';
  });

  useEffect(() => {
    window.localStorage.setItem('language', JSON.stringify(language));
  }, [language]);

  const german = () => {
    setLanguage('de');
  }

  const english = () => {
    setLanguage('en');
  }

  const chinese = () => {
    setLanguage('chn');
  }

    const [isMenuOpen, setMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);

      if (!isMenuOpen) {
        document.body.classList.add('fixed-position');
      } else {
        document.body.classList.remove('fixed-position');
      }
    };


  return (
    <Router>
      <div className="header-bg">
        <div className="header">
            <h1><a href="/"><img src="../assets/siona-logo-salzburg.png" alt="SIONA"></img></a></h1>
            <div className="menu">
                <a href="/">Home</a>
                <a href="/bags">Bags</a>
                <a href="/about">About</a>
                <a href="/contact">Contact</a>
                <button onClick={english}>EN</button>
                <button onClick={german}>DE</button>
                <button onClick={chinese}>CHN</button>
            </div>

            <div className="mobile-menu">
              <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
      
              {isMenuOpen && (
                <div className="menu-content">
                  <ul className="list-menu">
                    <div>
                    <li><a href="/">Home</a></li>
                    <li><a href="/bags">Bags</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                    </div>
                    <div className="lang-buttons-mobile">
                      <button onClick={english}>EN</button>
                      <button onClick={german}>DE</button>
                      <button onClick={chinese}>CHN</button>
                    </div>
                  </ul>
                </div>
              )}
            </div>
        </div>



      </div>
        {/* <h1> Lang: {language} </h1> */}

        <Routes>
        {language === 'chn' ? (
            <>
              <Route path="/" element={<HomeChn />} />
              <Route path="/bags" element={<BagsChn />} />
              <Route path="/about" element={<AboutChn />} />
              <Route path="/contact" element={<ContactChn />} />
              <Route path="/imprint" element={<ImpressumChn />} />
              <Route path="/privacy" element={<PrivacyChn />} />
            </>
          ) : language === 'en' ? (
            <>
              <Route path="/" element={<HomeEn />} />
              <Route path="/bags" element={<BagsEn />} />
              <Route path="/about" element={<AboutEn />} />
              <Route path="/contact" element={<ContactEn />} />
              <Route path="/imprint" element={<ImpressumEn />} />
              <Route path="/privacy" element={<PrivacyEn />} />
            </>
          ) : language === 'de' ? (
            <>
              <Route path="/" element={<HomeDe />} />
              <Route path="/bags" element={<BagsDe />} />
              <Route path="/about" element={<AboutDe />} />
              <Route path="/contact" element={<ContactDe />} />
              <Route path="/imprint" element={<ImpressumDe />} />
              <Route path="/privacy" element={<PrivacyDe />} />
            </>
          ) : (
            <>
              <Route path="/" element={<HomeDe />} />
              <Route path="/bags" element={<BagsDe />} />
              <Route path="/about" element={<AboutDe />} />
              <Route path="/contact" element={<ContactDe />} />
              <Route path="/imprint" element={<ImpressumDe />} />
              <Route path="/privacy" element={<PrivacyDe />} />
          </> 
          )}
        </Routes>
    </Router>
  );
};

export default AppRouter;
