import React from 'react';

const Impressum = () => {


  return (
    <>
   <div className='about-sec'>
      <video className='background-video' autoPlay muted loop>
          <source src="../assets/siona-salzburg-video3.mp4" type="video/mp4" />
      </video>
      <h1><span className='sec-bold-sec'>Impressum</span></h1>
    </div>

    <div className='imp-sec3-bg'>
    <div className='imp-sec3'>
      <h3>Impressum</h3>
      <p>Die Website dient der Selbstdarstellung sowie der Information über die Produkte der Shen KG. Sie gewährt jedem Interessierten freien und unmittelbaren Zugang. Alle Rechte liegen bei der Shen KG mit Sitz in Salzburg.
Diese ist auch verantwortlich im Sinne des Pressegesetzes.
</p>
<h3>Nutzungsbedingungen:</h3>
<p>Diese Website und die sie bildenden Werkteile sind urheberrechtlich geschützt.

Der Endbenutzer darf den Inhalt dieser Website auf seinem Computer online sichten und den Inhalt im übrigen fur seine eigenen, privaten Zwecke gemäß des österreichischen Urheberrechtsgesetzes verwenden. Alle weitergehenden Nutzungen (z.B. das Herstellen analoger Reproduktionen zwecks Veröffentlichung sowie das Herstellen digitaler Reproduktionen zwecks digitaler Übermittlung im Internet, Übersetzungen oder andere Bearbeitungen usw.) sind nur mit der schriftlichen Zustimmung des Herausgebers erlaubt. Der Herausgeber behält sich dabei das Recht vor, gegebenenfalls von der Erteilung der Zustimmung abzusehen. Das gleiche gilt fur Nutzungen, die über das Sichten und Herunterladen der Website hinausgehen, insbesondere das Einrichten sogenannter Deep Links und das Einbinden der Website in sogenannte Frames dritter Websites. Das Einrichten einfacher HTML-Links ist gestattet, wobei sich der Herausgeber allerdings das Recht vorbehält, vom Einrichter jederzeit und ohne Nennung eines Grundes die Löschung des Links binnen einer angemessenen Frist zu verlangen.

Der Herausgeber läßt bei der Beschaffung und Veröffentlichung der Informationen größtmögliche Sorgfalt im Hinblick auf Aktualitat, Genauigkeit und Vollständigkeit der Daten walten. Eine Garantie fur Richtigkeit und Vollständigkeit der Informationen wird jedoch nicht übernommen und jede Haftung fur Schäden, die auf fehlerhafte Informationen zurückzuführen sind sowie fur Schäden, die durch den Vorgang der Informationsübermittlung entstanden sind, wird ausgeschlossen, soweit dem Herausgeber keine grobe Fahrlässigkeit oder Vorsatz nachgewiesen werden kann.

Alle Zugriffe auf den diese Website beherbergenden Server werden im Hinblick auf Datum, Rechneradresse und gelesenes Dokument registriert und gespeichert. Die Daten werden eventuell für statistische Zwecke ausgewertet und möglicherweise anonymisiert veröffentlicht. Eine Weitergabe der Daten an Dritte fur kommerzielle Zwecke findet unter keinen Umständen statt.

Das Internetangebot der Shen KG enthält in Ihrem Interesse Links, die auf Internetseiten anderer Anbieter verweisen. Wenn Sie diesen Links folgen, verlassen Sie die Internetseiten der Shen KG. Dieses hat keinen Einfluss auf die Gestaltung und den Inhalt fremder Internetseiten und distanziert sich deshalb von allen fremden Inhalten, auch wenn seitens der Shen KG auf diese externen Seiten ein Link gesetzt wurde. Diese Erklärung gilt für alle auf unserer Homepage angezeigten Links und für alle Inhalte der Seiten, zu denen die bei uns angemeldeten Links und/oder allfällige Banner führen.

Durch den Besuch dieser Website erkennt der Endbenutzer die vorstehenden Nutzungsbedingungen als verbindlich an.
</p>
<h3>Medieninhaber:</h3>
<p>Shen KG<br></br>
Grießgasse 17 . 5020 Salzburg . Austria<br></br> 
T +43 660 3285 895  . salzburgsiona@gmail.com<br></br>
www.siona.at
</p>
<h3>Website & Design:</h3>
<div className='link-imp'><a href='https://farbzirkel.com'>www.farbzirkel.com</a></div>
    </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- ELEGANZ IN PERFEKTION.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESSE</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>KONTAKT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9 - 18 UHR<br></br>SA: 9 - 17 UHR</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9 - 19 UHR<br></br>SA: 9 - 18 UHR</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRESSUM</a><br></br><a href='/privacy'>DATENSCHUTZ</a></p>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Impressum;
