import React, { useState } from 'react';

const Home = () => {

  const images = ["../assets/slider/siona-weekender-bag.jpg", "../assets/slider/siona-handbag-1.jpg", "../assets/slider/siona-small-bag-1.jpg", "../assets/slider/siona-aktentasche.jpg", "../assets/slider/siona-backpack-men1.jpg", "../assets/slider/siona-handbag-2.jpg", "../assets/slider/siona-handbag-3.jpg", "../assets/slider/siona-handbag-4.jpg", "../assets/slider/siona-handbag-5.jpg"];
  const [currentIndex, setCurrentIndex] = useState(0);

  // const showImage = (index) => {
  //   setCurrentIndex(index);
  // };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };


  return (
    <div>
      <div className='home-hero-text'>
        <div>
        <h1>Elegance in perfection</h1>
        <p>Pure Refinement from Salzburg</p>
        
        <div className='button-hero'>
          <div className='button-primary'>
            <a href='/bags'>Our models</a>
          </div>
        </div>
        </div>
      </div>
    <div className='hero'>
    </div>

    <div className='sec0'>
      <div className='sec1'>
        <div className='text-home'>
          <p>Immerse yourself in the unparalleled elegance of our handmade bags, flowing from the depths of Italy directly into your life. Each SIONA bag is crafted with the utmost dedication and perfection by the skillful hands of a masterful Italian leather artisan. Only the finest materials find their way into our exclusive designs. Every structure, every stitch, and every handle is created with unsurpassed precision and care. In the picturesque backdrop of Salzburg, you will discover the sole and exclusive source of SIONA bags.</p>
        </div>

        <div className='gallerie-home'>
        <div className="gallery">
        <div>
        <img src={images[currentIndex]} alt={`Siona Bag ${currentIndex + 1}`}></img>
            <div className="nav-buttons">
              <img id="prev" src="../assets/icons/arrow-prev.svg" alt="Previous Icon" onClick={handlePrevClick}></img>
              <img id="next" src="../assets/icons/arrow-next.svg" alt="Next Icon" onClick={handleNextClick}></img>
            </div>
            </div>
        </div>
        </div>
      </div>

      <div className='sec1-mobile'>
        <div>
        <div className='text-home'>
          <p>Immerse yourself in the unparalleled elegance of our handmade bags, flowing from the depths of Italy directly into your life. Each SIONA bag is crafted with the utmost dedication and perfection by the skillful hands of a masterful Italian leather artisan. Only the finest materials find their way into our exclusive designs. Every structure, every stitch, and every handle is created with unsurpassed precision and care. In the picturesque backdrop of Salzburg, you will discover the sole and exclusive source of SIONA bags.</p>
        </div>

        <div className='gallerie-home'>
        <div className="gallery">
        <div>
        <img src={images[currentIndex]} alt={`Siona Bag ${currentIndex + 1}`}></img>
            <div className="nav-buttons">
              <img id="prev" src="../assets/icons/arrow-prev.svg" alt="Previous Icon" onClick={handlePrevClick}></img>
              <img id="next" src="../assets/icons/arrow-next.svg" alt="Next Icon" onClick={handleNextClick}></img>
            </div>
            </div>
        </div>
        </div>
        </div>
      </div>
    </div>

    <div className='sec2'>
      <div className='img-sec2'>
        <img src="../assets/siona-handbag-home2.jpg" alt="A brown Handbag"></img>
      </div>

      <div className='text-sec2'>
        <div className='sec2-text-button'>
        <h2>Discover the world of <span id="bold-h">Siona.</span></h2>
        <div className='button-sec2'>
          <div className='button-primary'>
            <a href='/contact'>Our stores</a>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className='sec3'>
      <div className='img-sec3'>
        <img src="../assets/siona-diashow.jpg" alt="Wallet on a stone"></img>
      </div>
    </div>

    <footer>
      <div className='footer-headline'>
      <h3><span id='footer-headline-bold'>SIONA </span>- Elegance in perfection.</h3>
      </div>
      <div className='footer-sections'>
        <div className='footer-sec1'>
          <h4>ADRESS</h4>
          <p>GRIESSGASSE 17<br></br>5020 SALZBURG<br></br>AUSTRIA</p>
        </div>

        <div className='footer-sec2'>
          <h4>CONTACT</h4>
          <p>+43 660 460 854 5<br></br>contact@siona.at</p>
        </div>

        <div className='footer-sec3'>
          <h4>MIRABELLPLATZ</h4>
          <p>MO - FR: 9am - 6pm<br></br>SA: 9am - 5pm</p>
        </div>

        <div className='footer-sec4'>
          <h4>GRIESGASSE</h4>
          <p>MO - FR: 9am - 7pm<br></br>SA: 9am - 6pm</p>
        </div>

        <div className='footer-sec5'>
          <h4>LEGAL</h4>
          <p><a href='/imprint'>IMPRINT</a><br></br><a href='/privacy'>PRIVACY</a></p>
        </div>


      </div>
    </footer>

    </div>



  );
};

export default Home;
